import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { Carousel } from 'react-responsive-carousel'

const StillLifeContent = () => {
  const data = useStaticQuery(graphql`
    {
      allPortfolioJson {
        edges {
          node {
            stillLife{
              name
              width
              height
              image
            }
          }
        }
      }
    }
  `)
  return data.allPortfolioJson.edges.map ( ({node}) => {
    const {stillLife} = node;
    return <div className = 'portfolio-slider' key = {stillLife}>
      <Carousel
        infiniteLoop
        autoPlay
      >
        {stillLife.map (stillLifeItem =>  <div className = 'portfolio__slide' key = {stillLifeItem.image}>
          <img src = {stillLifeItem.image} alt = {stillLifeItem.name}/> <br/>
          <div className = 'portfolio__slide__info'>
            <div className = 'portfolio__slide__info_title'>
              {stillLifeItem.name}
            </div>
            <div className = 'portfolio__slide__info_description'>
              Амбротип {stillLifeItem.width} X {stillLifeItem.height}
            </div>
          </div>
        </div>
        )}
      </Carousel>
    </div> 
  })
}

export default StillLifeContent
