import React from 'react'
import StillLifeContent from '../../components/StillLifeContent'
import Layout from '../../components/Layout'

export default function Portrets() {
  return (
    <Layout>
      <StillLifeContent/>
    </Layout>
  )
}
